import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";

import { Layout, RandomOp, Separator, Typography } from "../../components";
import {
  Figure,
  FigureDouble,
  List,
  MainPart,
  MainTitle,
} from "../../components/opComponents";

const RuptureDeLaCoiffeDesRotateurs = () => (
  <Layout
    title="Rupture de la coiffe des rotateurs : Diagnostic et traitement"
    description="Une rupture de la coiffe des rotateurs, peut être progressives ou post post traumatique, elle doit bien être bien diagnostiquée"
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link
        to="/chirurgie-de-lepaule"
        className="flex flex-row items-center space-x-3 uppercase text-primary group"
      >
        <FontAwesomeIcon
          className="transition-all duration-300 ease-in-out group-hover:text-link"
          icon="long-arrow-alt-left"
        />
        <Typography
          className="group-hover:text-link"
          weight="semiBold"
          transition="medium"
        >
          Retour aux opérations de l&apos;épaule
        </Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Rupture de la coiffe des rotateurs" />
        <MainPart title="Qu'est-ce qu'une rupture de la coiffe des rotateurs ?">
          <Typography weight="bold">
            La coiffe des rotateurs
            <Typography variant="span">
              {" "}
              est constituée par l&apos;insertion sur la tête de l&apos;humérus
              des tendons de quatre muscles : le sous-scapulaire, le
              supra-épineux, l&apos;infra-épineux et le petit rond.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment est constituée la coiffe des rotateurs ?">
          <Typography>
            Le muscle sous-scapulaire s&apos;insère au niveau de la face
            antérieure de la scapula. La face postérieure de la scapula est
            divisée en deux parties par l&apos;épine de la scapula : la fosse
            supra-épineuse sur laquelle s&apos;insère le muscle supra-épineux et
            la fosse infra-épineuse sur laquelle s&apos;insère du muscle
            infra-épineux. Le muscle petit rond prend son origine au niveau du
            bord latéral de la scapula (Figure 1).
          </Typography>
          <FigureDouble
            maxWidth="max-w-[500px]"
            height="h-[300px]"
            imageNameOne="figure1-rupturedelacoiffedesrotateurs.jpg"
            imageNameTwo="figure1bis-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 1. Muscle de la coiffe des rotateurs et muscle deltoïde"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
          <Typography>
            Le tendon du long biceps, chemine entre le sous-scapulaire et le
            supra-épineux (au niveau de l&apos;intervalle des rotateurs) et
            s&apos;insère au niveau de la partie supérieure de la glène de la
            scapula (tubercule supra-glénoïdien).
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les causes d'une rupture de la coiffe des rotateurs ?">
          <List>
            <List.Item>
              <Typography weight="bold">
                Conflit sous-acromial{" "}
                <Typography variant="span">
                  en rapport avec le frottement de l&apos;acromion avec les
                  tendons de la coiffe des rotateurs. Ce frottement répété est à
                  l&apos;origine d&apos;une inflammation de la bourse
                  sous-acromiale (bursite), d&apos;une érosion de la face
                  superficielle voire d&apos;une rupture transfixiante (totale)
                  des tendons de la coiffe des rotateurs (Figure 2).
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <FigureDouble
            maxWidth="max-w-[300px]"
            height="h-[150px]"
            imageNameOne="figure2-rupturedelacoiffedesrotateurs.jpg"
            imageNameTwo="figure2bis-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 2. Conflit sous-acrominal par frottement de l'acromion sur la coiffe des rotateurs"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
          <List>
            <List.Item>
              <Typography weight="bold">
                Génétique :{" "}
                <Typography variant="span">
                  plusieurs études récentes mettent en évidence
                  l&apos;importance du facteur génétique dans les ruptures de
                  coiffe.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Profession :{" "}
                <Typography variant="span">
                  les gestes répétitifs ainsi que le port de charges lourdes
                  au-dessus de la tête, à l&apos;origine de micro-traumatismes
                  de l&apos;épaule, favorisent la survenue d&apos;une rupture de
                  la coiffe des rotateurs.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Post-traumatique :{" "}
                <Typography variant="span">
                  dans le cadre d&apos;un traumatisme (sportif, voie
                  publique...) surtout chez les jeunes adultes.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Coté dominant :{" "}
                <Typography variant="span">
                  le coté dominant est le plus souvent concerné.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Tabac :{" "}
                <Typography variant="span">
                  joue un rôle prédominant dans la survenue et la cicatrisation
                  des lésions tendineuses.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Comment est diagnostiquée une rupture de la coiffe des rotateurs ?">
          <Typography>
            Les présentations cliniques sont différentes selon l&apos;étiologie.
            Nous distinguons ainsi deux situations de rupture de la coiffe des
            rotateurs (Figure 3) :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Ruptures progressives :{" "}
                <Typography variant="span">
                  surviennent dans le cadre d&apos;un conflit sous-acromial et
                  sont souvent bien tolérées en raison d&apos;une compensation
                  de la rupture par les tendons sains. Ces ruptures sont en
                  général révélées lors d&apos;un phénomène aigu (chute,
                  surmenage, port de charges lourdes...) et se traduisent par
                  des douleurs (le plus souvent nocturnes), une perte de
                  mobilité et de force de l&apos;épaule.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Ruptures post-traumatiques :{" "}
                <Typography variant="span">
                  surviennent suite à un traumatisme violent (sportif, voie
                  publique...) chez des patients jeunes ayant des tendons de la
                  coiffe des rotateurs de bonne qualité. Ces ruptures sont
                  responsables d&apos;une gêne fonctionnelle majeure de
                  l&apos;épaule.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Figure
            imageName="figure3-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 3. Tendinopathie de la coiffe des rotateurs et du tendon du long biceps"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer une rupture de la coiffe des rotateurs ?">
          <List>
            <List.Item>
              <Typography weight="bold">
                Radiographies de l&apos;épaule :{" "}
                <Typography variant="span">
                  mettent en évidence le bec acromial, une éventuelle arthrose
                  acromio-claviculaire et/ou calcification associée des tendons
                  de la coiffe des rotateurs (Figure 4).
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <FigureDouble
            maxWidth="max-w-[400px]"
            height="h-[200px]"
            imageNameOne="figure4-rupturedelacoiffedesrotateurs.jpg"
            imageNameTwo="figure4bis-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 4. Radiographies de l'épaule: important bec acromial (image de gauche), arthrose acromio-claviculaire (image de droite)"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
          <Typography>
            Les radiographies permettent également d&apos;éliminer les autres
            causes de douleur : arthrose gléno-humérale, fracture, pseudarthrose
            et tumeur de l&apos;épaule.
          </Typography>
          <List>
            <List.Item>
              <Typography variant="span" weight="bold">
                IRM et/ou arthro-scanner{" "}
                <Typography variant="span">
                  (Figure 5) : examens indispensables avant toute chirurgie de
                  la coiffe des rotateurs afin d&apos;apprécier :
                </Typography>
                <List>
                  <List.Item>
                    <Typography>
                      la qualité des tendons, le nombre de tendons rompus et
                      leur degré de rétraction
                    </Typography>
                  </List.Item>
                  <List.Item>
                    <Typography>
                      la qualité du corps musculaire : fonte musculaire
                      (amyotrophie) et/ou transformation du muscle en graisse
                      (involution graisseuse)
                    </Typography>
                  </List.Item>
                </List>
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[500px]"
            imageName="figure5-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 5. IRM de l'épaule: rupture de la coiffe des rotateurs (supra-épineux)"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux en cas de rupture de la coiffe des rotateurs ?">
          <div className="flex flex-col space-y-2">
            <Typography size="mdx" weight="bold">
              Prise en charge médicale
            </Typography>
            <Separator width="w-[100px]" />
          </div>
          <Typography>
            Le traitement médical est indispensable devant toute rupture de la
            coiffe des rotateurs dans le but d&apos;obtenir une épaule mobile et
            peu douloureuse :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                La rééducation permet un gain en mobilité et une diminution des
                douleurs en recentrant la tête de l&apos;humérus. Elle permet
                également de renforcer le muscle deltoïde afin de « soulager »
                les tendons de la coiffe des rotateurs.
              </Typography>
              <Typography>
                L&apos;infiltration est l&apos;injection de corticoïdes au
                niveau de l&apos;espace sous-acromial. Ce geste est effectué par
                un radiologue, sous contrôle écho ou radiographique.
                L&apos;infiltration permet de diminuer les douleurs de
                l&apos;épaule, surtout la nuit. Son efficacité est le plus
                souvent temporaire et le nombre d&apos;injection doit être
                limité en raison des effets secondaires.
              </Typography>
            </List.Item>
          </List>
          <div className="flex flex-col space-y-2">
            <Typography size="mdx" weight="bold">
              Prise en charge chirurgicale
            </Typography>
            <Separator width="w-[100px]" />
          </div>
          <Typography>
            Le traitement chirurgical est préconisé dans la majorité des cas.{" "}
            <Typography variant="span" weight="bold">
              En effet, toute rupture de la coiffe des rotateurs, même minime,
              aura tendance à s&apos;agrandir avec le temps
            </Typography>{" "}
            (tel une fermeture éclair sous tension !).
          </Typography>
          <Typography>
            L&apos;indication chirurgicale est posée en fonction de plusieurs
            facteurs :
          </Typography>
          <List>
            <List.Item>
              <Typography>Age physiologique du patient</Typography>
            </List.Item>
            <List.Item>
              <Typography>Motivation du patient</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Antécédents médicaux : antécédents cardio-vasculaires, prise
                d&apos;anticoagulants...
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>Consommation tabagique</Typography>
            </List.Item>
            <List.Item>
              <Typography>Absence d&apos;arthrose gléno-humérale</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Coiffe réparable : tendon de bonne qualité et peu rétracté,
                corps musculaire en bon état (peu ou pas de fonte musculaire
                et/ou de transformation graisseuse)
              </Typography>
            </List.Item>
          </List>
          <Typography weight="bold">
            L&apos;opération est réalisée sous anesthésie générale.{" "}
            <Typography variant="span">
              Une anesthésie locorégionale (bloc inter-scalénique) de complément
              permet de limiter les douleurs post-opératoires.
              L&apos;hospitalisation est de courte durée (1 nuit après
              l&apos;opération ou en ambulatoire). Le patient est installé en
              position demi-assise et l&apos;intervention est réalisée sous
              arthroscopie afin de garantir une chirurgie moins invasive, moins
              douloureuse avec une récupération plus rapide en post-opératoire
              (Figure 6).
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[500px]"
            imageName="figure6-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 6. Installation du patient en position demi-assise (“Beach Chair”) avant l'intervention"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
          <Typography>
            Quatre petites incisions sont réalisées au niveau de l'épaule afin
            d'y introduire l'optique et les instruments miniaturisés. Après
            avoir fait le bilan des lésions, le tendon rompu est préparé
            (nettoyage et libération) et la surface osseuse de l'humérus est
            avivée à l'aide d'une petite fraise afin de favoriser la
            cicatrisation tendineuse.
          </Typography>
          <Typography>
            Dans la majorité des cas, une réparation en double rangée de la
            coiffe des rotateur est réalisée :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                une première ancre médiale (contenant les fils et/ou
                bandelettes) est vissée dans la tête de l’humérus (Figure 7)
              </Typography>
              <Figure
                caption="Figure 7. Mise en place de l’ancre médiale dans la tête de l’humérus"
                imageName="figure7bis-rupturedelacoiffedesrotateurs.png"
                currentOpTitle="Rupture de la coiffe des rotateurs"
              />
            </List.Item>
            <List.Item>
              <Typography>
                les fils et/ou bandelettes sont ensuite passés dans le tendon
                rompu à l'aide d'une pince spéciale (Figure 8)
              </Typography>
              <Figure
                caption="Figure 8. Passage des fils et/ou bandelettes à travers la coiffe en utilisant la pince dédiée"
                imageName="figure8-rupturedelacoiffedesrotateurs.png"
                currentOpTitle="Rupture de la coiffe des rotateurs"
              />
            </List.Item>
            <List.Item>
              <Typography>
                les fils sont noués puis impactés dans l’humérus à l’aide d’une
                deuxième ancre latérale afin de mieux plaquer la coiffe des
                rotateurs sur la tête de l’humérus, favorisant ainsi la
                cicatrisation tendineuse (Figure 9)
              </Typography>
              <Figure
                caption="Figure 9. Les fils noués sont ensuite impactés sur une ancre latérale "
                imageName="figure9-rupturedelacoiffedesrotateurs.png"
                currentOpTitle="Rupture de la coiffe des rotateurs"
              />
            </List.Item>
          </List>
          <Typography>
            Il s’agit d’une réparation solide minimisant le risque de re-rupture
            de la coiffe des rotateurs.
          </Typography>

          <div className="flex flex-col space-y-2">
            <Typography size="mdx" weight="bold">
              Plusieurs gestes sont souvent associés à la réparation d&apos;une
              rupture de la coiffe des rotateurs :
            </Typography>
            <Separator width="w-[100px]" />
          </div>
          <List>
            <List.Item>
              <Typography weight="bold">
                Ténotomie ou ténodèse du long biceps :{" "}
                <Typography variant="span">
                  en cas d&apos;aspect dégénératif, le tendon du long biceps
                  peut être détaché de l&apos;articulation (ténotomie) et dans
                  certains cas, fixé sur l&apos;humérus (ténodèse). Ce geste
                  peut être responsable de douleurs et de crampes passagères du
                  biceps, sans perte de force. En cas de ténotomie simple, on
                  peut observer dans de rares cas, une descente du corps
                  musculaire du long biceps (
                  <a
                    className="underline text-link hover:text-primary"
                    href="https://www.sciencedirect.com/science/article/abs/pii/S1877051716305421"
                    target="_blank"
                    rel="noreferrer"
                  >
                    signe de « Popeye »
                  </a>
                  ) responsable d&apos;une gêne purement esthétique.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Acromioplastie :{" "}
                <Typography variant="span">
                  résection du bec osseux de l&apos;acromion afin de diminuer le
                  frottement avec la coiffe des rotateurs
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Résection acromio-claviculaire :{" "}
                <Typography variant="span">
                  en cas d&apos;arthrose douloureuse au niveau de cette
                  articulation
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography>
            L&apos;immobilisation est réalisée à l&apos;aide d&apos;un gilet
            orthopédique (réparation du sous-scapulaire) ou d&apos;un coussin
            d&apos;abduction (réparation du supra et/ou de l&apos;infra-épineux)
            pour une durée de 4 à 6 semaines selon la rupture (Figure 10).
          </Typography>
          <FigureDouble
            maxWidth="max-w-[600px]"
            imageNameOne="figure10-rupturedelacoiffedesrotateurs.jpg"
            imageNameTwo="figure10bis-rupturedelacoiffedesrotateurs.jpg"
            caption="Figure 10. Immoblisation post-opératoire: gilet coude au corps (image de gauche), anneaux claviculaires (image de droite)"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
          <Typography>
            La rééducation est débutée dès l&apos;ablation du gilet. La
            récupération des amplitudes et de la force se fait de manière
            progressive sur 6 à 12 mois. La reprise du sport est en général
            autorisée à partir du 4ème mois post-opératoire.
          </Typography>
          <Typography weight="bold">
            En cas de rupture non réparable des tendons de la coiffe des
            rotateurs{" "}
            <Typography variant="span">
              (importante rétraction, mauvaise qualité du tendon et du muscle),
              les principales options envisageables sont : la réparation
              partielle des tendons de la coiffe des rotateurs et les transferts
              tendineux (grand dorsal, trapèze inférieur) (Figure 11).
            </Typography>
          </Typography>
          <Figure
            caption="Figure 11. Résultat post-opératoire d'un transfert de grand dorsal dans le cadre d'une rupture irréparable de la coiffe des rotateurs"
            imageName="figure11-rupturedelacoiffedesrotateurs.jpg"
            currentOpTitle="Rupture de la coiffe des rotateurs"
          />
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une rupture de la coiffe des rotateurs ?">
          <Typography>
            En cas de réalisation de l&apos;opération par un chirurgien
            spécialisé du membre supérieur, les risques de complication sont
            rares. Il faut néanmoins prendre en compte les complications
            suivantes :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Complications liées à l&apos;anesthésie.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Infection :{" "}
                <Typography variant="span">
                  rare en raison de l&apos;application des règles d&apos;hygiène
                  et de l&apos;administration d&apos;une antibioprophylaxie
                  pendant l&apos;opération. Le germe le plus souvent incriminé
                  est le P.Acnès.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Hématome :
                <Typography variant="span">
                  {" "}
                  très rare, le plus souvent de petite taille.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Récidive de rupture de la coiffe des rotateurs :
                <Typography variant="span">
                  {" "}
                  secondaire à un défaut de cicatrisation du tendon sur
                  l&apos;os. Sa fréquence est estimée à 15% dans la littérature.
                  Elle est plus fréquemment observée en cas d&apos;importante
                  rétraction du tendon avec un muscle de mauvaise qualité.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La défaillance matérielle
                <Typography variant="span">
                  {" "}
                  (arrachage de l&apos;ancre et la rupture des fils de suture) :
                  beaucoup moins fréquente avec les nouvelles générations
                  d&apos;ancres et de fils ultrarésistants
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Douleur et raideur de l&apos;épaule :{" "}
                <Typography variant="span">
                  habituelle dans les suites post-opératoire. La récupération
                  est longue (6 à 12 mois) mais le plus souvent favorable avec
                  les séances de rééducation.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Capsulite rétractile
                <Typography variant="span">
                  {" "}
                  (ou algodystrophie) : se traduisant par des douleurs et un
                  enraidissement de l&apos;épaule pouvant atteindre tout le
                  membre supérieur. Il s&apos;agit d&apos;une complication non
                  prévisible, pouvant durer 12 à 18 mois.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="epaule" />
  </Layout>
);

export default RuptureDeLaCoiffeDesRotateurs;
